import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import axios from "axios"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SubscribeSection from "../components/SubscribeSection"

const API_URL = process.env.BASE_API_URL
const token = process.env.STRAPI_ACCESS_TOKEN

const ContactPage = () => {
  const { t } = useTranslation();

  const schema = {
    "@context": "http://schema.org/",
    "@type": "ContactPage",
    "description": t("contact1Description"),
    "image": "https://tbantsan.com/static/24a6738a973ec83999894104fb7a9c18/39310/logo-light.webp",
    "name": t("contact"),
    "potentialAction": t("phone") + " +90 216 394 83 70 "  + t("email") + " info@tbantsan.com " + t("quoteSection") + " " + t("quoteText")
  }

  const seo = {
    title: t("metaContactTitle"),
    description: t("metaContactDescription"),
    keywords: t("metaContactKeywords"),
    schema: schema
  }

  const initFormData = {
    Adı: "",
    Soyadı: "",
    "Cep Telefonu": "",
    "E-posta": "",
    Firma: "",
    Pozisyon: "",
    Mesaj: ""
  }

  const [formData, setFormData] = useState(initFormData)

  const email = t("infoEmail")

  const whatsappLink = `https://api.whatsapp.com/send?phone=905064772979&text=${t("contactWhatsappText")}`

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    text: "init"
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      text: `success`
    });
    if (ok) {
      // form.reset();
      // setFormData(initFormData)
    }
  };

  const sendEmail = async (values) => {
    return await axios
      .post(
        API_URL + "/tbant/email",
        { data: values },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
  }

  const handleContact = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true, text: "loading" });
    const emailInfo = {
      "to": "info@tbantsan.com",
      "subject": "İletişim talebi alındı. - tbantsan.com",
      "preheader": "İletişim talebi alındı. - tbantsan.com",
      "text": `İletişim talebi alındı. ${JSON.stringify(formData)}`,
      "cta": "",
      "ctaLink": "",
    }
    sendEmail(emailInfo)
      .then(r => handleServerResponse(true, "success", form))
      .catch(r => handleServerResponse(false, r.response.data.error, form))
  }


  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo {...seo} />

      <div className="bg-reallyDarkBlue text-white relative">
        <div className="max-w-7xl mx-auto px-6 md:px-10 py-10">
          <div className="text-center mx-auto max-w-xs md:max-w-xl lg:max-w-2xl">
            <h1 className="text-4xl lg:text-6xl font-bold">{t("contact")}</h1>
            <p className="text-lg p-10 font-light">
              {t("contact1Description")}
            </p>
          </div>

          <div className="w-full md:flex md:space-x-5 text-left py-10 lg:py-20">
            <div className="md:w-1/3">
              <h2 className="text-lg font-light">{t("phone")}</h2>
              <p className="text-2xl py-6 font-bold">
                <a href="tel:+902163948370" rel="noreferrer" target="_blank">+90 216 394 83 70</a>
              </p>
            </div>
            <div className="md:w-1/3">
              <h2 className="text-lg font-light">{t("email")}</h2>
              <p className="text-2xl py-6 font-bold">
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer"> {t("infoEmail")}</a>
              </p>
            </div>
            <div className="md:w-1/3">
              <h2 className="text-lg font-light">{t("quoteSection")}</h2>
              <p className="text-2xl py-6 font-bold">
                <Link to="/quote" className="border-b-2 border-white">{t("quoteText")}</Link>
              </p>
            </div>
          </div>
          {!serverState.status?.ok ?
            <form id="contact" name="contact" method="post" onSubmit={handleContact} >
              <input type="hidden" name="form-name" value="contact" />
              <div className="w-full md:flex md:space-x-5">
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${formData.Adı.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("nameLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                    required
                    type="text"
                    name="firstName"
                    placeholder={t("namePlaceholder")}
                    onChange={(e) => setFormData({ ...formData, Adı: e.target.value })}
                  />
                </div>
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${formData.Soyadı.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("surnameLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                    required
                    type="text"
                    name="lastName"
                    placeholder={t("surnamePlaceholder")}
                    onChange={(e) => setFormData({ ...formData, Soyadı: e.target.value })}
                  />
                </div>
              </div>

              <div className="w-full md:flex md:space-x-5">
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${formData["Cep Telefonu"].length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("phoneLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                    required
                    type="text"
                    name="phone"
                    placeholder={t("phonePlaceholder")}
                    onChange={(e) => setFormData({ ...formData, "Cep Telefonu": e.target.value })}
                  />
                </div>
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${formData["E-posta"].length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("emailLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                    required
                    type="email"
                    name="email"
                    placeholder={t("emailPlaceholder")}
                    onChange={(e) => setFormData({ ...formData, ["E-posta"]: e.target.value })}
                  />
                </div>
              </div>

              <div className="w-full md:flex md:space-x-5">
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${formData.Firma.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("companyLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                    required
                    type="text"
                    name="companyName"
                    placeholder={t("companyPlaceholder")}
                    onChange={(e) => setFormData({ ...formData, Firma: e.target.value })}
                  />
                </div>
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${formData.Pozisyon.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("positionLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                    required
                    type="text"
                    name="authorityPosition"
                    placeholder={t("positionPlaceholder")}
                    onChange={(e) => setFormData({ ...formData, Pozisyon: e.target.value })}
                  />
                </div>
              </div>

              <div className="w-full my-5 md:my-5 flex flex-col">
                <label className={`${formData.Mesaj.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("messageLabel")}*</label>
                <textarea
                  className="resize-marker-hidden py-2 outline-none bg-transparent border-b border-white placeholder-white text-white"
                  type="text"
                  name="message"
                  required
                  placeholder={t("messagePlaceholder") + "*"}
                  maxLength={255}
                  rows="1"
                  onChange={(e) => setFormData({ ...formData, Mesaj: e.target.value })}
                />
              </div>

              <div className="my-4"><p className="text-white text-right text-sm">{t("requiredFields")}</p></div>

              <div className="flex">
                <button
                  className="inline-block transition bg-purple px-5 mx-auto mt-16 py-2 text-white font-light border border-transparent hover:border-white hover:bg-darkBlue disabled:text-blue disabled:border-blue disabled:bg-logoGray disabled:cursor-auto"
                  type="submit"
                >
                  {t("sendFormCTA")}
                </button>
              </div>
            </form> :
            <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl mx-auto mb-32">
              <div className="text-center md:py-10 lg:py-20 p-10 flex flex-col justify-center items-center relative z-20">
                <h1 className="text-white font-bold text-5xl lg:text-6xl">{t("contactSuccessTitle")}</h1>
                <p className="text-white text-lg font-light my-16 whitespace-pre-line">
                  {t("contactSuccessDescription")}
                </p>
                <Link to="/" className="w-max block transition p-2 text-sm text-white font-light bg-purple border border-transparent hover:border-white hover:bg-darkBlue">
                  {t("backToHomeCTA")}
                </Link>
              </div>
            </div>
          }
        </div>

        <div className="max-w-7xl my-16 md:my-32 mx-auto px-6 md:px-10 py-10">
          <div className="w-full md:flex text-start items-stretch md:space-x-24">
            <div className="md:w-1/2">
              <iframe className="w-full h-[28.125rem]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.274461663699!2d29.360566115116086!3d40.887793979313166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cad9077a05380b%3A0x97cd5b14f3528b5d!2sT-Bant%20Sanayi%20ve%20Tic.%20A.%C5%9E.!5e0!3m2!1str!2str!4v1661433218379!5m2!1str!2str" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            </div>
            <div className="md:w-1/2">
              <p className="text-3xl lg:text-5xl font-semibold leading-tight py-4 md:pt-0 md:w-3/4">
                {t("contact2Title")}
              </p>
              <p className="text-lg font py-4 md:w-3/5 lg:pr-12 lg:py-1 font-light">
                {t("contact2Description1")}
              </p>
              <p className="text-lg font py-4 md:w-3/5 lg:pr-16 font-light">
                {t("contact2Description2")}
              </p>
              <div className="py-4 cursor-pointer">
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noreferrer"
                  className="w-max inline-block transition p-2 font-light text-sm text-white bg-purple border border-transparent hover:border-white hover:bg-darkBlue"
                >
                  {t("contact2CTA")}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t-[0.5px] border-blue">
          <SubscribeSection />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
   query ($language: String!){
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`