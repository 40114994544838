import React, { useState } from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import addToMailchimp from "gatsby-plugin-mailchimp"

const SubscribeSection = () => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null)

  const locale = i18n.language === "tr" ? { "group[214842][1]": "1" } : { "group[214842][2]": "2" }

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email, locale)
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg
        }
        setIsSubmitted(true)
        setError("")
      })
      .catch(err => {
        // alert(err)
        setError(err)
        setEmail("")
      })
  }

  return (
    <div className="px-6 md:px-10 pt-16 pb-16 lg:pb-48 xl:container lg:mx-auto">
      <div className="md:flex md:justify-between">
        <p className="text-3xl md:text-5xl md:w-2/5 leading-none font-semibold py-4 md:py-8 lg:py-16">
          {t("newsletterTitle")}
        </p>
        <p className="text-lg text-blue md:w-2/5 leading-normal py-4 md:py-8 lg:py-16">
          {t("newsletterDescription")}
        </p>
      </div>
      {!isSubmitted &&
        <form onSubmit={handleSubmit} className="box-content">
            <div className="pt-4">
              <input
                className="outline-none border-white focus:border-white border-b w-full md:min-w-full mb-1 py-2 bg-transparent placeholder-white"
                placeholder={t("newsletterPlaceholder")}
                required
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          {error && <p className="text-red text-xs">{t("newsletterError")}</p>}
          <div className="py-12">
            <input type="submit" value={t("newsletterCTA")} className="w-max transition block p-2 mx-auto md:mx-0 md:ml-auto md:mr-6 text-sm text-white bg-purple border border-transparent hover:border-white hover:bg-darkBlue cursor-pointer" />
          </div>
        </form>}

      {isSubmitted &&
        <div className="py-12">
          <p className="text-white">{t("newsletterSuccess")}</p>
        </div>}
    </div>
  )
}

export default SubscribeSection
